<template>
  <div class="index" :style="{ minHeight: minHeight }">
    <div @click="searchBtn" style="margin-bottom: -10px">
      <van-search
        background="#F6F6F6"
        :readonly="true"
        placeholder="输入医学公式关键词进行搜索"
      />
    </div>

    <!--    上新-->
    <div class="item">
      <div class="item_view nav">
        <div class="item_news_tag">
          <img src="../../assets/medical/new.png" style="width: 40px" alt="" />
        </div>
        <div
          style="border-right: #C3C3C3 1px solid;margin: 0 15px 0 10px;height: 45px;"
        />

        <van-swipe
          :autoplay="3000"
          indicator-color="#5169EF"
          style="height: 50px;flex: 1"
        >
          <van-swipe-item
            v-for="(item, index) in news"
            :key="index"
            @click="routeBtn(item, 'YXGS_HOME_NEW_LIST')"
          >
            <div class="swipe_title" style="-webkit-line-clamp:1;">
              {{ item.chineseName }}
            </div>
            <div class="swipe_title_sub">
              {{ item.englishName }}
            </div>
          </van-swipe-item>
        </van-swipe>

        <img
          v-if="news.length !== 0"
          src="../../assets/medical/upnew.png"
          style="height: 30px;right:0;top: 0;position: absolute"
          alt=""
        />
      </div>
            <!--   推荐专区-->
      <div class="item_view">
        <div style="width: 100%;margin-bottom: 10px">
          <img
            style=" width: 90px;"
            src="../../assets/medical/recommend.png"
            alt=""
          />
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="getRecommendList"
        >
          <div
            class="item_view_card"
            v-for="(item, index) in recommend"
            :key="item.id"
            @click="routeBtn(item, 'YXGS_HOME_RECOMMEND_LIST')"
          >
            <div style="display: flex;">
              <div
                class="hot_index"
                :style="{
                  color:
                    index === 0
                      ? '#F21448'
                      : index === 1
                      ? '#D15C0E'
                      : index === 2
                      ? '#AB8111'
                      : '#000000',
                }"
              >
                {{ index + 1 }}
              </div>
              <div>
                <div class="swipe_title">
                  {{ item.chineseName }}
                </div>
                <div class="swipe_title_sub">
                  {{ item.englishName }}
                </div>
              </div>
            </div>
            <div
              v-if="index !== recommend.length - 1"
              style=" border-bottom: #F6F6F6 solid 1px;margin: 10px 0"
            />
          </div>
        </van-list>
      </div>
      <!--      最近使用-->
      <div class="item_view" v-if="days.length !== 0">
        <div style="width: 100%;margin-bottom: 10px">
          <img
            style=" width: 75px;"
            src="../../assets/medical/day.png"
            alt=""
          />
        </div>

        <div
          class="item_view_card"
          v-for="(item, index) in days"
          :key="item.id"
          @click="routeBtn(item, 'YXGS_HOME_USE_LIST')"
        >
          <div>
            <div class="swipe_title">
              {{ item.chineseName }}
            </div>
            <div class="swipe_title_sub">
              {{ item.englishName }}
            </div>
          </div>
          <div
            v-if="index !== days.length - 1"
            style=" border-bottom:#F6F6F6 solid 1px;margin: 10px 0"
          />
        </div>
      </div>
      <!--      最近使用-->
      <div class="item_view" v-if="until.length !== 0">
        <div style="width: 100%;margin-bottom: 10px">
          <img
            style=" width: 110px;"
            src="../../assets/medical/until.png"
            alt=""
          />
        </div>

        <div
          class="item_view_card"
          v-for="(item, index) in until"
          :key="item.id"
          @click="routeBtn(item, 'YXGS_HOME_UNTIL_LIST')"
        >
          <div>
            <div class="swipe_title">
              {{ item.chineseName }}
            </div>
            <div class="swipe_title_sub">
              {{ item.englishName }}
            </div>
          </div>
          <div
            v-if="index !== until.length - 1"
            style=" border-bottom:#F6F6F6 solid 1px;margin: 10px 0"
          />
        </div>
      </div>
      <!--   热门-->
      <div class="item_view">
        <div style="width: 100%;margin-bottom: 10px">
          <img
            style=" width: 90px;"
            src="../../assets/medical/hots.png"
            alt=""
          />
        </div>

        <div
          class="item_view_card"
          v-for="(item, index) in hots"
          :key="item.id"
          @click="routeBtn(item, 'YXGS_HOME_HOT_LIST')"
        >
          <div style="display: flex;">
            <div
              class="hot_index"
              :style="{
                color:
                  index === 0
                    ? '#F21448'
                    : index === 1
                    ? '#D15C0E'
                    : index === 2
                    ? '#AB8111'
                    : '#000000',
              }"
            >
              {{ index + 1 }}
            </div>
            <div>
              <div class="swipe_title">
                {{ item.chineseName }}
              </div>
              <div class="swipe_title_sub">
                {{ item.englishName }}
              </div>
            </div>
          </div>
          <div
            v-if="index !== hots.length - 1"
            style=" border-bottom: #F6F6F6 solid 1px;margin: 10px 0"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  getMedicalDays,
  getMedicalHots,
  getMedicalNews,
  getMedicalUntil,
  getRecommend,
} from "@/service/api";

import { postDataBrowse } from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
export default {
  name: "medicalHome",
  data() {
    return {
      active: 0,
      minHeight: window.innerHeight - 100 + "px",
      searchValue: null,
      news: [],
      hots: [],
      days: [],
      until: [],
      recommend: [],
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 10,
    };
  },
  created(){
     if(this.$route.query.memberId){
          this.$store.commit('memberIdDocument', this.$route.query.memberId)
    }
   },
  mounted() {
    this.$store.commit("medicalMenu_typeIndex", 0);
    this.getNews();
    // this.getRecommendList();
  },
  methods: {
    async getNews() {
      const res = await getMedicalNews();
      if (res.code === 0) {
        this.news = res.data;
      }

      const hots = await getMedicalHots();
      if (hots.code === 0) {
        this.hots = hots.data;
      }
      const cook = {
        memberId: this.$store.state.memberIdDocument,
      };
      const days = await getMedicalDays(cook);
      if (days.code === 0) {
        this.days = days.data;
      }
      const until = await getMedicalUntil(cook);
      if (days.code === 0) {
        this.until = until.data;
      }
    },
    async getRecommendList() {
      const res = await getRecommend(this.pageNo, this.pageSize);
      if (res.code == 0) {
        // this.recommend = res.data;
        this.loading = false;
        let list = res.data;
        this.recommend = this.recommend.concat(list);
        if (this.recommend.length >= res.count) {
          this.finished = true;
        }
        if (this.recommend.length < res.count) {
          this.finished = false;
          this.pageNo += 1;
        }
      }
    },
   
    searchBtn() {
      this.$store.commit("editForm", {
        type: "YXGS_HOME",
        memberId: this.$store.state.memberIdDocument,
        channel: this.$route.query.channel,
      });
      this.$router.push({
        name: "medicalSearch",
        params: {},
      });
    },
    routeBtn(item, type) {
      //  加一个点击记录埋点
      if(this.$store.state.memberIdDocument==''){
        this.$router.push({
        path: "/formula/" + item.code,
        query: {
          id: item.id,
        
          channel: this.$route.query.channel,
          type: "h5",
          location: type,
        },
      });
      }
      else{
        this.$router.push({
        path: "/formula/" + item.code,
        query: {
          id: item.id,
          memberId: this.$store.state.memberIdDocument,
          channel: this.$route.query.channel,
          type: "h5",
          location: type,
        },
      });
      }
     
      this.addClickRecord(item, type);
    },
    async addClickRecord(item, type) {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$store.state.memberIdDocument,
        this.$route.query.channel
      );
      //点击埋点
      const data = {
        type: type, //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: item.id, //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: type,
        channel: this.$route.query.channel,
        os: arrays.os,
        memberId: this.$store.state.memberIdDocument,
      };
      console.log(data);

      await postDataBrowse(data);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .van-swipe__indicators {
  bottom: 1px !important;
}
::v-deep .van-search {
  padding: 10px 15px;
}
::v-deep .van-search__content {
  border-radius: 6px;
  background: white !important;
}
.hot_index {
  margin-right: 10px;
  padding-top: 2px;
  font-weight: bold;
  font-size: 17px;
  //font-family: Adobe Heiti Std;
}
.swipe_title {
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 2;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.swipe_title_sub {
  margin-top: 5px;
  color: #999999;
  font-size: 13px;
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 1;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
}
.nav {
  display: flex;
  position: relative;
}

.index {
  padding-bottom: 90px;
  height: auto;
  background: #f6f6f6;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
}
.item {
  width: 100%;
  background: #f6f6f6;

  .item_view {
    background: white;
    padding: 10px 15px;
    border-radius: 6px;
    margin: 10px 15px;

    .item_news_tag {
      text-align: center;
    }

    .item_view_card {
      align-items: center;
      padding-bottom: 10px;

      width: 100%;
    }
  }
}
</style>
